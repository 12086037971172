import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { memo, useState } from 'react';

import { MobileNav } from '@components';
import { menuItems } from '@constants';

import './header.scss';

const Header = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const toggleNav = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  return (
    <header
      id="header"
      className="on-show-on-scroll"
      data-offset-el=".index-section"
      data-offset-behavior="bottom"
      role="banner">
      <div id="logoWrapper" className="on-wrapper" data-content-field="site-title">
        <div id="logoImage">
          <a href="https://www.thejamiemcmartingroup.com/">
            <img
              src="//images.squarespace-cdn.com/content/v1/5f9c8145d8965652bc7e11e1/7e4381ca-db04-4709-a798-f79705b41a7d/JMG_Horizontal+Logo+Black+%28002%29+-+Copy.png?format=1500w"
              alt="The Jamie McMartin Group"
            />
          </a>
        </div>
      </div>

      <div id="headerNav">
        <div id="mainNavWrapper" className="on-nav-wrapper" data-content-field="navigation-mainNav">
          <nav id="mainNavigation" data-content-field="navigation-mainNav">
            <div className="on-external">
              <a
                href="https://search.thejamiemcmartingroup.com/"
                target="_blank"
                rel="noreferrer"
                aria-label=" (opens in new tab)"
                alt=" (opens in new tab)"
                title=" (opens in new tab)">
                Buy
              </a>
            </div>

            <div className="on-folder">
              <div className="on-folder-toggle" data-href="/sell">
                Sell
              </div>
              <div className="on-subnav">
                <div className="on-external">
                  <a href="https://www.thejamiemcmartingroup.com/home-valuation"> Home Valuation </a>
                </div>

                <div className="on-collection">
                  <a href="https://www.thejamiemcmartingroup.com/why-list-with-us"> Why List With Us </a>
                </div>
              </div>
            </div>

            <div className="on-folder">
              <div className="on-folder-toggle" data-href="/communities-1">
                Communities
              </div>
              <div className="on-subnav">
                <div className="on-external">
                  <a href="https://www.thejamiemcmartingroup.com/communities/katy-real-estate"> Katy </a>
                </div>

                <div className="on-external">
                  <a href="https://www.thejamiemcmartingroup.com/communities/houston-real-estate"> Houston </a>
                </div>

                <div className="on-external">
                  <a href="https://www.thejamiemcmartingroup.com/communities/galveston-real-estate"> Galveston </a>
                </div>

                <div className="on-external">
                  <a href="https://www.thejamiemcmartingroup.com/communities/cane-island-real-estate">Cane Island</a>
                </div>

                <div className="on-external">
                  <a href="https://www.thejamiemcmartingroup.com/communities/bridgeland-real-estate"> Bridgeland </a>
                </div>

                <div className="on-external">
                  <a href="https://www.thejamiemcmartingroup.com/communities/cross-creek-ranch-real-estate">
                    {' '}
                    Cross Creek Ranch{' '}
                  </a>
                </div>

                <div className="on-external">
                  <a href="https://www.thejamiemcmartingroup.com/communities/elyson-real-estate"> Elyson </a>
                </div>

                <div className="on-external">
                  <a href="https://www.thejamiemcmartingroup.com/communities/fulbrook-on-fulshear-creek-real-estate">
                    {' '}
                    Fulbrook On Fulshear Creek{' '}
                  </a>
                </div>

                <div className="on-external">
                  <a href="https://www.thejamiemcmartingroup.com/communities/jordan-ranch-real-estate">Jordan Ranch</a>
                </div>

                <div className="on-external">
                  <a href="https://www.thejamiemcmartingroup.com/communities/tamarron-real-estate"> Tamarron </a>
                </div>

                <div className="on-collection">
                  <a href="https://www.thejamiemcmartingroup.com/communities"> More Communities </a>
                </div>
              </div>
            </div>

            <div className="on-collection">
              <a href="https://www.thejamiemcmartingroup.com/team-page"> Team </a>
            </div>

            <div className="on-collection">
              <a href="https://www.thejamiemcmartingroup.com/testimonials"> Testimonials </a>
            </div>

            <div className="on-collection">
              <a href="https://www.thejamiemcmartingroup.com/contact-us"> Contact Us </a>
            </div>

            <div className="on-collection">
              <a href="https://www.thejamiemcmartingroup.com/blog"> Blog </a>
            </div>

            <div className="on-collection on-active on-homepage">
              <a href="https://www.thejamiemcmartingroup.com/"> Home </a>
            </div>
          </nav>
        </div>
      </div>
      <FontAwesomeIcon className="mobile-button" icon="bars" onClick={toggleNav} size="2x" />
      <MobileNav items={menuItems} className="on-mobile-menu" isOpen={mobileNavOpen} toggleNav={toggleNav} />
    </header>
  );
};

export default memo(Header);
