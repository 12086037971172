export const menuItems = [
  {
    id: 1,
    htmlId: 'menu-home',
    displayName: 'Buy', // TODO: I18n this
    url: 'https://search.thejamiemcmartingroup.com/',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: false,
  },
  {
    id: 2,
    htmlId: 'menu-meet',
    displayName: 'Sell', // TODO: I18n this
    url: 'http://www.domain.com/link',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [
      {
        id: 201,
        htmlId: 'menu-listings-sold',
        displayName: 'Home Valuation ', // TODO: I18n this
        url: 'https://www.thejamiemcmartingroup.com/home-valuation',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 202,
        htmlId: 'menu-listings-soon',
        displayName: 'Why List With Us ', // TODO: I18n this
        url: 'https://www.thejamiemcmartingroup.com/why-list-with-us',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
    ],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 3,
    htmlId: 'menu-listings',
    displayName: 'Communities', // TODO: I18n this
    url: 'http://www.domain.com/link',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [
      {
        id: 301,
        htmlId: 'menu-listings-active',
        displayName: 'Katy', // TODO: I18n this
        url: 'https://www.thejamiemcmartingroup.com/communities/katy-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 302,
        htmlId: 'menu-listings-new',
        displayName: 'Houston', // TODO: I18n this
        url: 'https://www.thejamiemcmartingroup.com/communities/houston-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 303,
        htmlId: 'menu-listings-weekend',
        displayName: 'Galveston', // TODO: I18n this
        url: 'https://www.thejamiemcmartingroup.com/communities/galveston-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 304,
        htmlId: 'menu-listings-sold',
        displayName: 'Cane Islan', // TODO: I18n this
        url: 'https://www.thejamiemcmartingroup.com/communities/cane-island-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 305,
        htmlId: 'menu-listings-soon',
        displayName: 'Bridgeland', // TODO: I18n this
        url: 'https://www.thejamiemcmartingroup.com/communities/bridgeland-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },

      {
        id: 306,
        htmlId: 'menu-listings-sold',
        displayName: 'Cross Creek Ranch', // TODO: I18n this
        url: 'https://www.thejamiemcmartingroup.com/communities/cross-creek-ranch-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 307,
        htmlId: 'menu-listings-soon',
        displayName: 'Elyson', // TODO: I18n this
        url: 'https://www.thejamiemcmartingroup.com/communities/elyson-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },

      {
        id: 308,
        htmlId: 'menu-listings-sold',
        displayName: 'Fulbrook On Fulshear Creek', // TODO: I18n this
        url: 'https://www.thejamiemcmartingroup.com/communities/fulbrook-on-fulshear-creek-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 309,
        htmlId: 'menu-listings-soon',
        displayName: 'Jordan Ranc', // TODO: I18n this
        url: 'https://www.thejamiemcmartingroup.com/communities/jordan-ranch-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },

      {
        id: 310,
        htmlId: 'menu-listings-sold',
        displayName: 'Tamarron', // TODO: I18n this
        url: 'https://www.thejamiemcmartingroup.com/communities/tamarron-real-estate',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
      {
        id: 311,
        htmlId: 'menu-listings-soon',
        displayName: 'More Communities', // TODO: I18n this
        url: 'https://www.thejamiemcmartingroup.com/communities',
        // ariaLabel: "", // TODO: a11y
        className: 'nav-submenu-item listings-menu',
        mobileClassName: 'mobile-nav-submenu-item listings-menu',
        children: [],
        displayOnMobile: true,
        displayOnDesktop: true,
      },
    ],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 4,
    htmlId: 'menu-buyers',
    displayName: 'Team', // TODO: I18n this
    url: 'https://www.thejamiemcmartingroup.com/team-page',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 5,
    htmlId: 'menu-sellers',
    displayName: 'Testimonials', // TODO: I18n this
    url: 'https://www.thejamiemcmartingroup.com/testimonials',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 6,
    htmlId: 'menu-for-lease',
    displayName: 'Contact Us', // TODO: I18n this
    url: 'https://www.thejamiemcmartingroup.com/contact-us',
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 7,
    htmlId: 'menu-communities',
    displayName: 'Blog', // TODO: I18n this
    url: 'https://www.thejamiemcmartingroup.com/blog',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
  {
    id: 8,
    htmlId: 'menu-concierge',
    displayName: 'Home', // TODO: I18n this
    url: 'https://www.thejamiemcmartingroup.com/',
    // ariaLabel: "", // TODO: a11y
    className: 'nav-menu-item',
    mobileClassName: 'mobile-nav-menu-item',
    children: [],
    displayOnMobile: true,
    displayOnDesktop: true,
  },
];
